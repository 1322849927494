import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagesAuthGuard implements CanActivate, CanLoad {
  userDetails = {
    email: '',
    user_type: ''
};

constructor(private _router: Router) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (!!localStorage.getItem('currentUserCaseMgt') == true) {
        if (this.userDetails) {
          return true;
        }
        else {
            localStorage.clear();
            sessionStorage.clear();
            this._router.navigate(['login']);
        }

    } else {
        this._router.navigate(['login']);
    }

    return false;
}

canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (!!localStorage.getItem('currentUserCaseMgt') == true) {
        if (this.userDetails) {
          return true;
        }
        else {
            localStorage.clear();
            sessionStorage.clear();
            this._router.navigate(['login']);
        }
    } else {
        this._router.navigate(['login']);
    }

    return false;
}
}
