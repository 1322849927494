import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { PopperContent } from 'ngx-popper';
import { MatDialog } from '@angular/material/dialog';
import { GlobalStateService } from 'projects/case-manager/src/app/services/globalStateService';
import { CaseManagementService } from 'projects/case-manager/src/app/services/caseManagementService';
import { SettingService } from 'projects/case-manager/src/app/services/settingsService';
import { ConfirmDialogComponent } from 'projects/case-manager/src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessageService } from 'projects/case-manager/src/app/services/error-message.service';

@Component({
  selector: 'app-caselist',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss'],
})
export class CaselistComponent implements OnInit {
  cases = [];
  allCases = [];
  checkedList = [];
  users = [];
  accessLogs = [];
  usersUsernameHash = {};
  channels = [];
  statuses = [];
  statusesHash = {};
  reportTypes = [];
  groups = [];
  groupsNameHash = {};
  sortedData: any;
  loading = false;
  filters: any = {
    status: ['All Cases'],
  };
  exportingNotAllowed = false;
  subscriptions: Subscription[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  selectedType = '';
  userSelected = new FormControl('');
  duplicateErrorMsg = [];
  errorMessage = '';
  tempObj = {};
  resetCases = [];
  permissions = undefined;
  particularCase = undefined;
  matchingDelId: any;
  matchingArcId: any;
  matchingEscId: any;
  initialLoading = true;
  toggleDropdown = false;
  selectAllChecked: boolean = false;
  dataList: any[] = [];
  date = null;
  placement: NzDrawerPlacement = 'left';
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  userDetails = null;
  statusFilters = ['All Cases', 'Active', 'Resolved', 'Archived', 'Deleted'];
  statusesFilters = [
    { value: ['A', 'C', 'R', 'D'], viewValue: 'All Cases' },
    { value: 'A', viewValue: 'Active' },
    { value: 'R', viewValue: 'Resolved' },
    { value: 'C', viewValue: 'Archived' },
    { value: 'D', viewValue: 'Deleted' },
  ];
  public staticDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    itemsShowLimit: 2,
    closeDropDownOnSelection: false,
  };
  @ViewChild('selectAll') selectAll: ElementRef;
  @ViewChild('checkboxes') checkboxes: ElementRef;
  @ViewChild('assignCasePopper') assignCasePopper: PopperContent;
  @ViewChild('exportCasePopper') exportCasePopper: PopperContent;
  isAssignCaseModalVisible = false;
  searchCase = '';

  constructor(
    private _router: Router,
    public _globalStateService: GlobalStateService,
    private caseMgt: CaseManagementService,
    public settingService: SettingService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private errorMessageService: ErrorMessageService
  ) { }

  ngOnInit(): void {
    this.caseSearch()
    this.spinner.show('initialLoad');
    this.spinner.show('casesLoading');
    this.loading = true;
    this.initialLoading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

    //get users.
    this.subscriptions.push(
      this.settingService.getUsers().subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.users = res.users;
            this.users.forEach((user) => {
              this.usersUsernameHash[user.id] = user.username;
            });
          } else {
            this.users = [];
          }
        },
        (err) => {
          this.users = [];
        }
      )
    );

    // get Access Logs
    this.subscriptions.push(
      this.caseMgt.getAccessLogs().subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.accessLogs = res.accessLogs;
          } else {
            this.accessLogs = [];
          }
        },
        (err) => {
          this.accessLogs = [];
        }
      )
    );

    //get permissions, cases, groups & choices.
    this.subscriptions.push(
      this.settingService
        .getUserPermissions({
          user_id: this.userDetails.id.toString(),
        })
        .subscribe(
          (res) => {
            if (res.status == 'ok') {
              const permissions = res['userPermissions']['permission_tags'];
              const permissionsArray = Array.from(new Set(permissions));
              this.permissions =
                this._globalStateService.MakePermissionsArray(permissionsArray);

              if (this.permissions && Object.keys(this.permissions).length) {
                this.subscriptions.push(
                  this.settingService.getGroups().subscribe(
                    (response) => {
                      if (response.status == 'ok') {
                        // if (response.groups && response.groups.length) {
                        this.groups = response.groups;

                        // creating groups name hash
                        this.groups.forEach((group) => {
                          this.groupsNameHash[group.id] = group.name;
                        });

                        this.subscriptions.push(
                          this.settingService.getChoices().subscribe((res) => {
                            if (res.status == 'ok') {
                              res.choices.alert_choices[
                                'CASE_STATUS_CHOICES'
                              ].map((temp) => {
                                this.statuses.push({
                                  value: temp[0],
                                  viewValue: temp[1],
                                });
                              });

                              // set status hash
                              this.statuses.map((status) => {
                                this.statusesHash[status.value] =
                                  status.viewValue;
                              });

                              res.choices.alert_choices[
                                'REPORT_TYPE_CHOICES'
                              ].map((temp) => {
                                this.reportTypes.push({
                                  value: temp[0],
                                  viewValue: temp[1],
                                });
                              });

                              if (
                                this.permissions &&
                                this.reportTypes &&
                                this.reportTypes.length &&
                                !this.permissions.canExportDetailedReport
                              ) {
                                let index = this.reportTypes.findIndex(
                                  (res) => res.value == 'D'
                                );
                                if (index != -1)
                                  this.reportTypes.splice(index, 1);
                              }

                              if (
                                this.permissions &&
                                this.reportTypes &&
                                this.reportTypes.length &&
                                !this.permissions.canExportSummaryReport
                              ) {
                                let index = this.reportTypes.findIndex(
                                  (res) => res.value == 'S'
                                );
                                if (index != -1)
                                  this.reportTypes.splice(index, 1);
                              }

                              if (
                                this.permissions &&
                                this.reportTypes &&
                                this.reportTypes.length &&
                                !this.permissions.canAuditCase
                              ) {
                                this.reportTypes = this.reportTypes.filter(
                                  (type) => {
                                    const auditLogs = ['E', 'C', 'T', 'X'];

                                    return !auditLogs.includes(type.value);
                                  }
                                );
                              }

                              this.reportTypes = [
                                ...new Map(
                                  this.reportTypes.map((item) => [
                                    item['value'],
                                    item,
                                  ])
                                ).values(),
                              ];

                              this.initialLoading = false;
                              this.spinner.hide('initialLoad');
                              this.loading = true;
                              this.spinner.show('casesLoading');

                              // this.subscriptions.push(
                                this.getCases();
                              // );
                            } else {
                              this.statuses = [];
                              this.reportTypes = [];
                            }
                          })
                        );
                      } else {
                        this.groups = [];
                      }
                    },
                    (err) => {
                      this.groups = [];
                    }
                  )
                );
                // this.spinner.hide('casesLoading');
                this.spinner.hide('initialLoad');
              } else {
                this.spinner.hide('casesLoading');
                this.spinner.hide('initialLoad');
                this.permissions = null;
              }
            } else {
              this.permissions = null;
            }
          },
          (err) => {
            this.permissions = null;
          }
        )
    );

    //get error messages
    this.subscriptions.push(
      this.errorMessageService.errorMessage.subscribe((message) => {
        if (message) {
          this.errorMessage = message.split(':').pop();

          setTimeout(() => {
            this.errorMessage = '';
          }, 7000);
        }
      })
    );
  }

  /**
   * Search Term in a Table
   * @param value
   */
  // filterData(value: any) {
  //   setTimeout(() => {
  //     if (!value) {
  //       this.cases = this.allCases;
  //       return;
  //     }

  //     this.cases = this.allCases.filter((obj) => {
  //       return (
  //         matchTerm(obj, 'name', value) ||
  //         matchTerm(obj, 'id', value) ||
  //         matchTerm(obj, 'case_id', value) ||
  //         matchTerm(obj, 'escalated', value) ||
  //         matchTerm(obj, 'status', value) ||
  //         matchTerm(obj, 'created_by', value)
  //       );
  //     });
  //   }, 300);
  // }

  getCases(){
    this.caseMgt.listActiveCases(false).subscribe(
      (response) => {
        if (response.status == 'ok') {
          if (
            response.cases &&
            response.cases.length
          ) {
            this.cases = response.cases;
            this.resetCases = this.cases;
            this.cases = this.cases.filter((el) => {
              return el.status != 'D';
            });
            // this.cases.reverse();

            // set all cases for search purpose
            this.allCases = JSON.parse(
              JSON.stringify(this.cases)
            );

            this.loading = false;
            this.spinner.hide('casesLoading');
          } else {
            this.cases = [];
            this.loading = false;
            this.spinner.hide('casesLoading');
          }
        } else {
          this.cases = [];
          this.loading = false;
          this.spinner.hide('casesLoading');
        }
      },
      (err) => {
        this.cases = [];
        this.loading = false;
        this.spinner.hide('casesLoading');
      }
    )
  }

  trackByFn(i) {
    return i;
  }

  CloseExport() {
    this.selectedType = '';
    this.exportCasePopper.hide();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  
  onCheckboxChange(detail, checked) {
    // event.stopPropagation();
    let index = this.checkedList.findIndex((x) => x.id == detail.id);
    if (checked) {
      detail.selected = true;
      this.checkedList = this.checkedList.concat(detail.id);
    } else if (
      !checked &&
      this.selectAll &&
      this.selectAll.nativeElement &&
      this.selectAll.nativeElement.checked
    ) {
      detail.selected = false;
      this.selectAll.nativeElement.checked = false;
      this.checkedList.splice(index, 1);
    } else {
      detail.selected = false;
      this.checkedList.splice(index, 1);
    }
    this.updateCheckedSet(detail.id, checked);
    this.selectAllChecked = this.cases.every((data) =>
    this.setOfCheckedId.has(data.id)
    );
  }

  onSelectAllClicked() {
    this.selectAllChecked = !this.selectAllChecked;
    // Update the state of all individual checkboxes
    if (this.selectAllChecked) {
      // Select all checkboxes
      this.setOfCheckedId = new Set(this.cases.map((data) => data.id));
    } else {
      // Deselect all checkboxes
      this.setOfCheckedId.clear();
    }
  }

  exportCases() {
    if (this.selectedType) {
      if (!this.filters || !Object.values(this.filters).length) {
        this.toastr.info('Add filter to export data!');
        this.selectedType = '';
        this.exportCasePopper.hide();
        return;
      }
      if (!this.exportingNotAllowed) {
        let obj: any = null;
        // let apiRoute = '';

        if (this.filters.dates && this.filters.dates.length) {
          this.filters.dates[0] = this.convert(this.filters.dates[0]);
          this.filters.dates[1] = this.convert(this.filters.dates[1]);
        }

        switch (this.selectedType) {
          case 'D': {
            obj = {
              r_type: 'D',
              filters: {
                start_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[0]
                    : '',
                end_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[1]
                    : '',
              },
            };
            break;
          }
          case 'S': {
            obj = {
              r_type: 'S',
              filters: {
                start_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[0]
                    : '',
                end_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[1]
                    : '',
              },
            };
            break;
          }
          case 'E': {
            obj = {
              r_type: 'E',
              filters: {
                user_id_1:
                  this.filters && this.filters.escalator
                    ? this.filters.escalator
                    : '',
                user_id_2:
                  this.filters && this.filters.escalatee
                    ? this.filters.escalatee
                    : '',
                start_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[0]
                    : '',
                end_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[1]
                    : '',
              },
            };
            break;
          }
          case 'C': {
            obj = {
              r_type: 'C',
              filters: {
                user_id_1:
                  this.userDetails && this.userDetails.id
                    ? this.userDetails.id
                    : '',
                start_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[0]
                    : '',
                end_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[1]
                    : '',
              },
            };
            break;
          }
          case 'T': {
            obj = {
              r_type: 'T',
              filters: {
                user_id_1:
                  this.userDetails && this.userDetails.id
                    ? this.userDetails.id
                    : '',
                start_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[0]
                    : '',
                end_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[1]
                    : '',
              },
            };
            break;
          }
          case 'X': {
            obj = {
              r_type: 'X',
              filters: {
                user_id_1:
                  this.userDetails && this.userDetails.id
                    ? this.userDetails.id
                    : '',
                view_name:
                  this.filters && this.filters.view_name
                    ? this.filters.view_name
                    : '',
                start_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[0]
                    : '',
                end_date:
                  this.filters && this.filters.dates
                    ? this.filters.dates[1]
                    : '',
              },
            };
            break;
          }
        }
        // apiRoute = CaseReportType[this.selectedType];

        this.caseMgt.exportCases(obj).subscribe(
          (res) => {
            if (res.status == 'ok') {
              this.exportCasePopper.hide();
              this.toastr.success(
                'Cases delivered for exporting successfully!'
              );
              this.setOfCheckedId.clear();
              this.getCases();
            } else {
              // this.toastr.error(res.error || 'Error in exporting report!');
            }
          },
          (err) => {
            // this.toastr.error('Error in exporting report!');
          }
        );
      } else {
        this.toastr.info('No case to export!');
        this.selectedType = '';
        this.exportCasePopper.hide();
        return;
      }
    }
  }

  GotoCaseDetails(data) {
    this._router.navigate(['pages/case-management/case-view', data.id]);
    localStorage.setItem('caseId', data.id);
    this.caseMgt.passData.next(data);
  }

  sortData(sort) {
    const data = this.cases.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return true;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'case_id':
          return this.compare(a.case_id, b.case_id, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'escalated':
          return this.compare(a.escalated, b.escalated, isAsc);
        // case 'ogs':
        //   return this.compare(a.ogs, b.ogs, isAsc);
        case 'records_count':
          return this.compare(a.records_count, b.records_count, isAsc);
        case 'assigned_to':
          return this.compare(a.assigned_to, b.assigned_to, isAsc);

        // case 'channel':
        //   return this.compare(a.channel, b.channel, isAsc);
        case 'group':
          return this.compare(a.group, b.group, isAsc);
        // case 'created_by':
        //   return this.compare(a.created_by, b.created_by, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
    this.cases = data;
    return this.cases;
  }

  Close() {
    this.assignCasePopper.hide();
    this.userSelected.setValue('');
  }

  TransformQuery(query) {
    return JSON.stringify(query).replace(/[{}]/g, '').replace(/['"]+/g, '');
  }

  deleteCase(event, data?) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Are you sure you want to delete this case?' },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          let ids = '';
          // if (this.checkedList && this.checkedList.length) {
          if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
            // ids = this.checkedList.join(',');
            ids = Array.from(this.setOfCheckedId).join(',');
            this.spinner.show('bulkDeleteLoading');
          } else {
            this.spinner.show('deleteLoading');
            this.matchingDelId = data.id;
          }
          this.caseMgt
            .deleteACase(
              this.setOfCheckedId && this.setOfCheckedId.size > 0
                ? ids
                : data.id.toString()
            )
            .subscribe(
              (res) => {
                // console.log(res)
                if (res.status == 'ok') {
                  if (res.rejectedCases && res.code == '') {
                    if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
                      this.spinner.hide('bulkDeleteLoading');
                    } else {
                      this.spinner.hide('deleteLoading');
                    }
                    // this.toastr.error(
                    //   `Case ID ${res.rejectedCases} is not archieved, therefore it can't be deleted.`
                    // );
                  } else if (res.rejectedCases && res.code != '') {
                    if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
                      this.spinner.hide('bulkDeleteLoading');
                    } else {
                      this.spinner.hide('deleteLoading');
                    }
                    // this.toastr.error(
                    //   'You cannot delete unarchieved case, Archieve a case first!'
                    // );
                  } else {
                    if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
                      this.spinner.hide('bulkDeleteLoading');
                      Array.from(this.setOfCheckedId).map((res) => {
                        let index = this.cases.findIndex((tr) => tr.id == res);
                        this.cases[index].status = 'D';
                        this.cases.splice(index, 1);
                      });
                    } else {
                      this.spinner.hide('deleteLoading');
                      let index = this.cases.findIndex(
                        (tr) => tr.id == data.id
                      );
                      this.cases[index].status = 'D';
                      this.cases.splice(index, 1);
                    }
                    this.toastr.success(
                      'Case ' +
                      (this.setOfCheckedId && this.setOfCheckedId.size > 0
                        ? ids
                        : data.id) +
                      ' deleted successfully!'
                    );
                    this.setOfCheckedId.clear();
                    this.selectAllChecked = false;
                    this.getCases();
                  }
                  // this.checkedList = [];
                  // let elements =
                  // this.checkboxes && this.checkboxes.nativeElement
                  // ? this.checkboxes.nativeElement.querySelectorAll(
                  //   '.option_input'
                  //   )
                  //     : [];
                  //     elements.forEach((element) => {
                  //       if (element.checked) {
                  //         element.checked = false;
                  //       }
                  //     });
                    } else {
                      this.toastr.error(res.error);
                      if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
                        this.spinner.hide('bulkDeleteLoading');
                      } else {
                        this.spinner.hide('deleteLoading');
                      }

                  if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
                    this.spinner.hide('bulkDeleteLoading');
                  } else {
                    this.spinner.hide('deleteLoading');
                  }
                  // this.toastr.error('Error in deleting case, Try again!');
                }
              },
              (err) => {
                let errorMsg = err.error;
                let error = errorMsg.split(':')[1].trim();
                this.toastr.error(error);
                if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
                  this.spinner.hide('bulkDeleteLoading');
                } else {
                  this.spinner.hide('deleteLoading');
                }
                // this.checkedList = [];

                // let elements =
                //   this.checkboxes && this.checkboxes.nativeElement
                //     ? this.checkboxes.nativeElement.querySelectorAll(
                //       '.option_input'
                //     )
                //     : [];

                // elements.forEach((element) => {
                //   if (element.checked) {
                //     element.checked = false;
                //   }
                // });
                // this.toastr.error('Error in deleting case, Try again!');
              }
            );
        }
      });
  }

  EscalateCase(event, data) {
    event.stopPropagation();
    this.matchingEscId = data.id;

    this.spinner.show('escalateLoading');

    this.caseMgt.escalateCase(data.id).subscribe(
      (res) => {
        if ((res as any).status == 'ok') {
          this.spinner.hide('escalateLoading');
          this.assignCasePopper.hide();
          let index = this.cases.findIndex((res) => res.id == data.id);

          this.cases[index].escalated = true;
          this.cases[index].escalated_level =
            this.cases[index].escalated_level + 1;
          this.cases = this.cases;
          this.toastr.success('Case ' + data.id + ' escalated successfully!');
          this.setOfCheckedId.clear();
          this.getCases();
        } else {
          this.spinner.hide('escalateLoading');
          // this.toastr.error(
          //   res && res['error']
          //     ? res['error']
          //     : 'Error in escalating case, Try again!'
          // );
        }
      },
      (err) => {
        this.spinner.hide('escalateLoading');
        // this.toastr.error('Error in escalating case, Try again!');
      }
    );
  }

  popperAssignShow(event, data) {
    event.stopPropagation();
    setTimeout(() => {
      this.cases.forEach((element) => {
        if (element.id == data.id) {
          this.particularCase = data;
        }
      });
    }, 0);
  }

  assignCase() {
    let ids = '';
    // if (this.checkedList && this.checkedList.length) {
    if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
      // ids = this.checkedList.join(',');
      ids = Array.from(this.setOfCheckedId).join(',');
      console.log(ids)
      this.spinner.show('bulkAssignLoading');
    } else {
      this.spinner.show('assignLoading');
    }

    this.caseMgt
      .assignCase(
        // this.checkedList && this.checkedList.length
        this.setOfCheckedId && this.setOfCheckedId.size > 0
          ? ids
          : this.particularCase.id,
        this.userSelected.value
      )
      .subscribe(
        (res) => {
          if ((res as any).status == 'ok') {
            // if (this.checkedList && this.checkedList.length) {
            if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
              this.spinner.hide('bulkAssignLoading');
              // this.checkedList.map((res) => {
              Array.from(this.setOfCheckedId).map((res) => {
                let index = this.cases.findIndex((tr) => tr.id == res);
                this.cases[index].assigned_to = this.userSelected.value;
                this.cases = this.cases;
              });

              this.userSelected.setValue('');
              this.isAssignCaseModalVisible = false;
            } else {
              this.spinner.hide('assignLoading');
              let index = this.cases.findIndex(
                (res) => res.id == this.particularCase.id
              );
              this.cases[index].assigned_to = this.userSelected.value;
              this.cases = this.cases;
              this.userSelected.setValue('');
              this.isAssignCaseModalVisible = false;
            }

            this.assignCasePopper.hide();
            this.toastr.success(
              'Case ' +
              (this.setOfCheckedId && this.setOfCheckedId.size > 0
                ? Array.from(this.setOfCheckedId).join(',')
                : this.particularCase.id) +
              ' assigned successfully!'
            );
            this.setOfCheckedId.clear();
            this.selectAllChecked = false;
            this.getCases();
            // this.checkedList = [];
            this.userSelected.setValue('');
            this.isAssignCaseModalVisible = false;

            // let elements =
            //   this.checkboxes && this.checkboxes.nativeElement
            //     ? this.checkboxes.nativeElement.querySelectorAll(
            //       '.option_input'
            //     )
            //     : [];

            // elements.forEach((element) => {
            //   if (element.checked) {
            //     element.checked = false;
            //   }
            // });
          } else {
            // if ((res as any).code == 'HS002') {
            //   this.toastr.error(
            //     'Case ' +
            //     (this.checkedList && this.checkedList.length
            //       ? this.checkedList.join(',')
            //       : this.particularCase.id) +
            //     ' cannot be assigned to selected user, since assignee doesnot have escalation role!'
            //   );
            // } else {
            //   this.toastr.error('Error in assigning case, Try again!');
            // }

            // if (this.checkedList && this.checkedList.length) {
            if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
              this.spinner.hide('bulkAssignLoading');
            } else this.spinner.hide('assignLoading');

            this.assignCasePopper.hide();
            this.userSelected.setValue('');
            this.isAssignCaseModalVisible = false;
          }
        },
        (err) => {
          // if (this.checkedList && this.checkedList.length) {
          if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
            this.spinner.hide('bulkAssignLoading');
          } else this.spinner.hide('assignLoading');

          this.assignCasePopper.hide();
          this.userSelected.setValue('');
          this.isAssignCaseModalVisible = false;
          // this.toastr.error('Error in assigning case, Try again!');
        }
      );
  }

  archieveCase(event, data?) {
    event.stopPropagation();

    let ids = '';
   // if (this.checkedList && this.checkedList.length) {
    if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
      // ids = this.checkedList.join(',');
      ids = Array.from(this.setOfCheckedId).join(',');

      this.spinner.show('bulkArchieveLoading');
    } else {
      this.spinner.show('archieveLoading');
      this.matchingArcId = data.id;
    }

    this.caseMgt
      .archieveACase(
        this.setOfCheckedId && this.setOfCheckedId.size > 0 ? ids : data.id.toString()
      )
      .subscribe(
        (res) => {
          if ((res as any).status == 'ok') {
            if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
              this.spinner.hide('bulkArchieveLoading');
              Array.from(this.setOfCheckedId).map((res) => {
                let index = this.cases.findIndex((tr) => tr.id == res);
                this.cases[index].status = 'C';
                this.cases = this.cases;
              });
            } else {
              this.spinner.hide('archieveLoading');
              let index = this.cases.findIndex((tr) => tr.id == data.id);
              this.cases[index].status = 'C';
              this.cases = this.cases;
            }
            this.toastr.success(
              'Case ' +
              (this.setOfCheckedId && this.setOfCheckedId.size > 0 ? ids : data.id) +
              ' archived successfully!'
            );
            this.setOfCheckedId.clear();
            this.selectAllChecked = false;
            this.getCases();
            // this.checkedList = [];
            // let elements =
            //   this.checkboxes && this.checkboxes.nativeElement
            //     ? this.checkboxes.nativeElement.querySelectorAll(
            //       '.option_input'
            //     )
            //     : [];

            // elements.forEach((element) => {
            //   if (element.checked) {
            //     element.checked = false;
            //   }
            // });
          } else {
            if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
              this.spinner.hide('bulkArchieveLoading');
            } else {
              this.spinner.hide('archieveLoading');
            }
            // if ((res as any).code == 'HS002') {
            //   this.toastr.error(
            //     'You cannot archive a case without resolving each record in it, Resolve each record within it first!'
            //   );
            // } else {
            //   if (this.checkedList && this.checkedList.length) {
            //     this.spinner.hide('bulkArchieveLoading');
            //   } else  {
            //     this.spinner.hide('archieveLoading');
            //   }
            //   this.toastr.error('Error in archiving case, Try again!');
            // }
          }
        },
        (err) => {
          if (this.setOfCheckedId && this.setOfCheckedId.size > 0) {
            this.spinner.hide('bulkArchieveLoading');
          } else {
            this.spinner.hide('archieveLoading');
          }
          // this.toastr.error('Error in archieving case, Try again!');
        }
      );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  filterByStatus() {
    if (this.filters.status.length > 1) {
      this.filters.status = this.filters.status.filter((e) => e !== 'All');
    }

    if (this.filters.status && this.filters.status.length > 0) {
      let stats = [];
      this.filters.status.forEach((res) =>
        this.statusesFilters.forEach((te) => {
          if (te.viewValue === res) {
            if (te.viewValue === 'All' && res === 'All') {
              stats = [...te.value];
            } else {
              stats.push(te.value);
            }
          }
        })
      );

      this.statuses = [...new Set(stats)];
    }
  }

  deleteFilterStatus() {
    if (this.filters.status.length === 0) {
      this.filters.status = ['All Cases'];
    }

    if (this.filters.status && this.filters.status.length > 0) {
      let stats = [];
      this.filters.status.forEach((res) =>
        this.statusesFilters.forEach((te) => {
          if (te.viewValue === res) {
            if (te.viewValue === 'All Cases' && res === 'All Cases') {
              stats = [...te.value];
            } else {
              stats.push(te.value);
            }
          }
        })
      );

      this.statuses = [...new Set(stats)];
    }
  }

  resetCaseFilters() {
    this.filters.assigned_to = '';
    this.filters.status = ['All Cases'];
    this.filters.dates = [];
    this.statuses = [];
    this.filters.searchValue = '';
    this.cases = this.resetCases
  }
  
  caseSearch() {
    if (this.filters.searchValue) {
      this.cases = this.cases.filter(({ id, case_id, name }) => {
        return (
          id?.toString().toLowerCase().trim().includes(this.filters.searchValue.toLowerCase().trim()) ||
          case_id?.toString().toLowerCase().trim().includes(this.filters.searchValue.toLowerCase().trim()) ||
          name?.toString().toLowerCase().trim().includes(this.filters.searchValue.toLowerCase().trim())
          
        )
      }
      )

    } else {
      this.cases = this.resetCases
    }
  }

  filterCases() {
    let obj = {};

    if (this.filters.dates && this.filters.dates.length) {
      obj['start_date'] = this.convert(this.filters.dates[0]);
      obj['end_date'] = this.convert(this.filters.dates[1]);
    }

    if (
      this.filters.status &&
      this.filters.status.length &&
      !this.filters.status.includes('All Cases')
    ) {
      if (this.statuses.some((status) => !!status.value)) {
        obj['query_filter_type'] = this.statuses.filter((status) => {
          if (this.filters.status.includes(status.viewValue)) {
            return status.value;
          }
        });
      } else {
        obj['query_filter_type'] = this.statuses;
      }
    } else {
      obj['query_filter_type'] = ['A', 'C', 'R', 'D'];
    }

    if (this.filters.assigned_to) {
      obj['assigned_to'] = +this.filters.assigned_to;
    }

    if (this.filters && this.filters.searchValue) {
      obj['name'] = this.filters.searchValue;
    }

    this.loading = true;
    this.subscriptions.push(
      this.caseMgt.listActiveCases(true, obj).subscribe(
        (res) => {
          if (res.status == 'ok') {
            this.cases = res.cases;
            if (this.cases && !this.cases.length)
              this.exportingNotAllowed = true;
            else this.exportingNotAllowed = false;
            this.loading = false;
          } else {
            this.cases = [];
            this.loading = false;
          }
        },
        (err) => {
          this.cases = [];
          this.loading = false;
        }
      )
    );
  }

  convert(str) {
    let date = new Date(str);
    let mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  openAssignCaseModal(event, data) {
    event.stopPropagation();

    setTimeout(() => {
      this.cases.forEach((element) => {
        if (element.id == data.id) {
          this.particularCase = data;
        }
      });
    }, 0);

    this.isAssignCaseModalVisible = true;
  }

  handleCancel() {
    this.userSelected.setValue('');
    this.isAssignCaseModalVisible = false;
  }

  handleOk() {
    this.userSelected.setValue('');
    this.isAssignCaseModalVisible = false;
  }

  formatCreatedOn(createdOn: string): string {
    if (createdOn) {
      const date = new Date(createdOn);
      return date.toDateString();
    } else {
      return "-";
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.errorMessageService.errorMessage.next('');
  }
}
